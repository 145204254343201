import React from 'react'
import styles from './news_list_item.module.css'

export const NewsListItem = ({ url, title, publishedAt }: { url: string; publishedAt: string; title: string }) => {
  return (
    <div className={styles.container}>
      <a className={styles.box} href={url}>
        <span className={styles.date}>{i18n.l('date.formats.default2', publishedAt)}</span>
        <h2 className={styles.title}>{title}</h2>
      </a>
    </div>
  )
}
