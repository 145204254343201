import React from 'react'
import { CtaBar, CtaButtonGroup } from 'shared'
import { ProjectCtaBarProps } from './types'
export const ProjectCtaBar = ({ shareUrl, projectStatus: status, ...props }: ProjectCtaBarProps) => {
  const { projectTitle, progressPercentage, carrierBlockedString } = props
  const carrierBlocked = carrierBlockedString === 'true'
  const isNotDonatable = status !== 'activated' || progressPercentage >= 100 || carrierBlocked

  return (
    <CtaBar>
      <a href={shareUrl} className="mr-auto">
        {projectTitle}
      </a>
      <CtaButtonGroup
        shareUrl={shareUrl}
        hideDonateButton={isNotDonatable}
        status={status}
        carrierBlocked={carrierBlocked}
        utmMedium="blog"
        {...props}
      />
    </CtaBar>
  )
}
