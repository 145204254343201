import React, { useCallback, useState } from 'react'
import ReactPaginate from 'react-paginate'
import classNames from 'classnames'
import styles from './news_list.module.css'
import { BlogPostsApiResult } from 'ppp/news_teaser_list/types'
import { LogoSpinner, findApiV4Link, scrollToTop, themeColor, useJsonApi } from 'shared'
import { NewsListItem } from 'news/news_list_item'

export const NewsList = ({ apiUrl }: { apiUrl: string }) => {
  const [activePage, setActivePage] = useState(0)
  const perPage = 20
  const { data: blogList, loading } = useJsonApi<BlogPostsApiResult>(
    // activePage is a zero-based index, but the page parameter starts at 1
    apiUrl + `?per_page=${perPage}&page=${activePage + 1}&order=published_at:desc`
  )

  const handlePageChange = useCallback(({ selected }: { selected: number }) => {
    setActivePage(selected)
    scrollToTop()
  }, [])

  if (loading) {
    return (
      <div className={styles.loadingContainer}>
        <LogoSpinner smallSpinner color={themeColor('green-500')} />
      </div>
    )
  }

  if (!blogList || blogList.total_entries === 0) {
    return (
      <div className={classNames(styles.container, styles.noResultContainer)}>
        <span>{i18n.t('news.blogs_list.no_result')}</span>
      </div>
    )
  }

  return (
    <div className={styles.container}>
      {blogList.data.map((blog) => (
        <NewsListItem
          key={blog.id}
          title={blog.title}
          publishedAt={blog.published_at}
          url={findApiV4Link({ ofObject: blog, withRel: 'platform' }) || ''}
        />
      ))}

      {blogList.total_entries > perPage && (
        <div className="mt-4">
          <ReactPaginate
            pageCount={Math.ceil(blogList.total_entries / blogList.per_page)}
            onPageChange={handlePageChange}
            forcePage={activePage} // https://github.com/AdeleD/react-paginate/issues/198
            pageRangeDisplayed={4}
            marginPagesDisplayed={0}
            nextLabel={<i className={'fa fa-caret-right text-green-800'}></i>}
            previousLabel={<i className={'fa fa-caret-left text-green-800'}></i>}
            containerClassName={'pagination'}
            activeClassName={'current'}
            renderOnZeroPageCount={() => null}
            nextAriaLabel={i18n.t('misc.pagination.next')}
            previousAriaLabel={i18n.t('misc.pagination.previous')}
            ariaLabelBuilder={(page, current) => {
              return current ? i18n.t('misc.pagination.current', { page }) : i18n.t('misc.pagination.goto', { page })
            }}
          />
        </div>
      )}
    </div>
  )
}
